<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("products.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("products.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button class="btn btn-success btn-add mr-2" @click="onImport">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("global.import") }}
              </button>
              <button class="btn btn-success btn-add mr-lg-3" @click="onAdd">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("products.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('products.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3"></b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th v-for="hd in $t('products.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="6" />
              </b-tbody>
              <b-tbody v-else-if="this.totalData !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="(produk, index) in dataProduk"
                  :key="produk.id"
                >
                  <b-td>
                    <!-- <input
                      type="checkbox"
                      :value="produk.id"
                      v-model="prodIDs"
                      @click="select"
                    /> -->
                    {{ ++index + (currPage - 1) * perPage }}
                  </b-td>
                  <b-td>{{ produk.brand }}</b-td>
                  <b-td>{{ produk.category.category }}</b-td>
                  <!-- <b-td style="width: 20%">
                    <span v-if="produk.detail_produk.length !== 0">
                      <div
                        class="badge badge-secondary mr-1"
                        v-for="detail in produk.detail_produk"
                        :key="detail.id"
                      >
                        {{ detail.crops.crop }}
                      </div>
                    </span>
                  </b-td> -->
                  <!-- <b-td>{{ produk.regHolder }}</b-td> -->
                  <b-td style="width: 10%;">
                    <b-form-checkbox
                      v-model="produk.isSample"
                      name="check-button"
                      @change="publishSample(produk.id, produk.isSample)"
                      switch
                    />
                  </b-td>
                  <b-td style="width: 10%;">
                    <b-form-checkbox
                      v-model="produk.isMatpro"
                      name="check-button"
                      @change="publishMatpro(produk.id, produk.isMatpro)"
                      switch
                    />
                  </b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(produk)"
                      ></i>
                      <i
                        class="ri-edit-fill ri-lg ri-mid action-btn"
                        @click="onEdit(produk)"
                      ></i>
                      <i
                        class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                        @click="onDelete(produk.id)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-produk" centered hide-footer>
      <template #modal-header="{}">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label for="nama_produk" class="ssp-16 text-black">
              {{ $t("products.label.name") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.nama_produk.$error }"
              id="nama-country"
              v-model="$v.formTambah.nama_produk.$model"
              :placeholder="$t('products.placeholder.name')"
              :disabled="isDisabled"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.nama_produk.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ingredients" class="ssp-16 text-black">
              {{ $t("products.label.active_ingr") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.ingredient.$error }"
              id="ingredients"
              cols="30"
              rows="4"
              :placeholder="$t('products.placeholder.active_ingr')"
              v-model="$v.formTambah.ingredient.$model"
              :disabled="isDisabled"
            ></textarea>
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.ingredient.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ingredients" class="ssp-16 text-black">
              {{ $t("products.label.category") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <custom-select
              :placeholder="$t('products.placeholder.category')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.category.$error
              }"
              v-model="$v.formTambah.category.$model"
              :options="dataKategori.map(ctg => ctg.id)"
              :custom-label="
                opt => dataKategori.find(x => x.id == opt).category
              "
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.$error">
              <div v-if="$v.formTambah.category.$error">
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="ingredients" class="ssp-16 text-black">
              {{ $t("products.label.reg_plants") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <custom-select
              v-model="$v.formTambah.crops.$model"
              tag-placeholder="Add this as new tag"
              :placeholder="$t('products.placeholder.reg_plants')"
              label="crop"
              track-by="id"
              :options="dataTanaman"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :class="{
                'invalid-form rounded-input': $v.formTambah.crops.$error
              }"
              :disabled="isDisabled"
            ></custom-select>
            <div class="text-sm text-red">
              <div v-if="$v.formTambah.crops.$error">
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="label-produk" class="ssp-16 text-black"
              >Label <span v-if="!isDetail" class="text-red">*</span></label
            >
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.label.$error }"
              id="label-produk"
              cols="30"
              rows="4"
              placeholder="Masukkan label produk disini"
              v-model="$v.formTambah.label.$model"
              :disabled="isDetail"
            ></textarea>
            <div class="text-sm text-red" v-if="$v.formTambah.label.$error">
              <div>Label tidak boleh kosong</div>
            </div>
          </div> -->
          <div class="form-group">
            <label for="dose-mlgr" class="ssp-16 text-black">
              {{ $t("products.label.conc") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.doseGr.$error }"
              id="dose-mlgr"
              v-model="$v.formTambah.doseGr.$model"
              :placeholder="$t('products.placeholder.conc')"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.doseGr.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="dose-hakg" class="ssp-16 text-black">
              {{ $t("products.label.dose") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.doseHa.$error }"
              id="dose-hakg"
              v-model="$v.formTambah.doseHa.$model"
              :placeholder="$t('products.placeholder.dose')"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.doseHa.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="reg-holder" class="ssp-16 text-black"
              >Pemegang Registrasi
              <span v-if="!isDetail" class="text-red">*</span></label
            >
            <select
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.regHolder.$error }"
              v-model="$v.formTambah.regHolder.$model"
              :disabled="isDetail"
            >
              <option :value="null" selected disabled>
                Pilih Pemegang Registrasi
              </option>
              <option
                v-for="data in regHolderOptions"
                :key="data.id"
                :value="data.value"
                >{{ data.label }}</option
              >
            </select>
            <div class="text-sm text-red" v-if="$v.formTambah.regHolder.$error">
              <div>Pemegang registrasi tidak boleh kosong</div>
            </div>
          </div> -->
          <!-- <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black"
              >Nomor Registrasi
              <span v-if="!isDetail" class="text-red">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.regNumber.$error }"
              id="reg-number"
              v-model="$v.formTambah.regNumber.$model"
              placeholder="Masukkan nomor registrasi produk disini"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.regNumber.$error">
              <div>No. registrasi tidak boleh kosong</div>
            </div>
          </div> -->
          <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black">
              {{ $t("products.label.price") }}
            </label>
            <vue-numeric
              currency="Rp"
              separator="."
              v-model="$v.formTambah.price.$model"
              class="form-control"
              :placeholder="$t('products.placeholder.price')"
              :disabled="isDisabled"
            ></vue-numeric>
          </div>
          <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black">
              {{ $t("products.label.dpt") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.dosisPerTangki.$error }"
              id="reg-number"
              v-model="$v.formTambah.dosisPerTangki.$model"
              :placeholder="$t('products.placeholder.dpt')"
              :disabled="isDisabled"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.dosisPerTangki.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black">
              {{ $t("products.label.not") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.jumlahTangki.$error }"
              id="reg-number"
              v-model="$v.formTambah.jumlahTangki.$model"
              :placeholder="$t('products.placeholder.not')"
              :disabled="isDisabled"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.jumlahTangki.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black">
              {{ $t("products.label.dpd") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.dosisPerDemo.$error }"
              id="reg-number"
              v-model="$v.formTambah.dosisPerDemo.$model"
              :placeholder="$t('products.placeholder.dpd')"
              :disabled="isDisabled"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.dosisPerDemo.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="reg-number" class="ssp-16 text-black">
              {{ $t("products.label.sps") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{
                'invalid-form': $v.formTambah.ukuranKemasanSample.$error
              }"
              id="reg-number"
              v-model="$v.formTambah.ukuranKemasanSample.$model"
              :placeholder="$t('products.placeholder.sps')"
              :disabled="isDisabled"
            />
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.ukuranKemasanSample.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <button
            v-if="!isDetail"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
    <b-modal id="modal-import-produk" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ $t("global.import") }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit="handleImport">
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("products.label.excel") }}
              <span class="text-red"> *</span>
            </label>
            <b-form-file
              v-model="dataImport"
              :placeholder="$t('global.excel_pch')"
              drop-placeholder="Drop file here..."
            >
            </b-form-file>
          </div>
          <div class="form-group mb-0">
            <div class="mb-1">
              {{ $t("products.rule.index") }}
              <ul class="no-bullets">
                <li v-for="rule in $t('products.rule.rule')" :key="rule.id">
                  {{ `- ${rule}` }}
                </li>
              </ul>
            </div>
            <a :href="$window.location.origin + '/files/produk-template.xlsx'">
              produk-template.xlsx
            </a>
          </div>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            {{ $t("global.submit_btn") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";
import VueNumeric from "vue-numeric";

import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import {
  Q_LIST_PRODUK,
  M_ADD_PRODUK,
  M_DEL_PRODUK,
  M_EDIT_PRODUK,
  Q_LIST_CATEGORY,
  Q_LIST_CROP
} from "@/graphql/super_admin";
import { RegHolder } from "@/graphql/enum.type.js";
import * as XLSX from "xlsx";
import JwtService from "@/core/services/jwt.service";
import { publishMatpro, publishSample } from "@/graphql/mutations";

export default {
  components: {
    Card,
    "custom-select": Multiselect,
    VueNumeric
  },
  data() {
    return {
      isLoading: false,
      loading: true,
      dataProduk: [],
      dataKategori: [],
      dataTanaman: [],

      prodIDs: [],
      selected: [],
      allSelected: false,

      valueSelected: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      regHolderOptions: [RegHolder.PT_CAM, RegHolder.PT_UPLI],

      modalTitle: "",
      isEdit: false,
      isDisabled: false,
      isDetail: false,
      dataImport: null,

      formTambah: {
        id: "",
        nama_produk: "",
        ingredient: "",
        category: "",
        crops: [],
        label: "",
        price: 0,
        doseGr: "",
        doseHa: "",
        regHolder: "",
        regNumber: "",
        dosisPerTangki: "",
        jumlahTangki: "",
        dosisPerDemo: "",
        ukuranKemasanSample: ""
      }
    };
  },
  validations: {
    formTambah: {
      nama_produk: { required },
      ingredient: { required },
      category: { required },
      crops: {},
      label: {},
      doseGr: { required },
      doseHa: { required },
      regHolder: {},
      regNumber: {},
      price: {},
      dosisPerTangki: { required },
      jumlahTangki: { required },
      dosisPerDemo: { required },
      ukuranKemasanSample: { required }
    }
  },
  apollo: {
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput
        };
      },
      fetchPolicy: "network-only",
      debounce: 500,
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
        // console.log(this.dataProduk);
        this.totalPages = data.listProduk.totalPage;
        this.totalData = data.listProduk.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    }
  },
  methods: {
    async fetchKategori() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_CATEGORY,
          variables: {
            page: 1,
            limit: 9999,
            keyword: ""
          }
        });
        this.dataKategori = result.data.listCategoryProduk.categories;
        // console.log(result);
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchTanaman() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_CROP,
          variables: {
            page: 1,
            limit: 999999,
            keyword: ""
          }
        });
        this.dataTanaman = result.data.listCropName.crops;
        // console.log(result);
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    onAdd() {
      this.modalTitle = this.$t("products.add");
      this.isEdit = false;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();
      this.fetchKategori();
      this.fetchTanaman();
      this.$bvModal.show("modal-produk");
    },
    async onDetail(data) {
      this.resetForm();
      await this.fetchKategori();
      this.modalTitle = this.$t("products.detail");
      this.isDetail = true;
      this.isDisabled = true;
      this.$bvModal.show("modal-produk");

      let editVar = data;
      for (let index = 0; index < data.detail_produk.length; index++) {
        if (data.detail_produk[index].crops) {
          data.detail_produk[index] = data.detail_produk[index].crops;
        }
      }

      this.formTambah.id = editVar.id;
      this.formTambah.nama_produk = editVar.brand;
      this.formTambah.ingredient = editVar.ingredient;
      this.formTambah.category = editVar.category.id;
      this.formTambah.crops = editVar.detail_produk;
      this.formTambah.label = editVar.label;
      this.formTambah.doseGr = editVar.dosegr;
      this.formTambah.doseHa = editVar.doseHaKg;
      this.formTambah.regHolder = editVar.regHolder;
      this.formTambah.regNumber = editVar.regNum;

      this.formTambah.price = editVar.price;
      this.formTambah.dosisPerTangki = editVar.volume_per_demoplot;
      this.formTambah.jumlahTangki = editVar.jumlah_tangki;
      this.formTambah.dosisPerDemo = editVar.kebutuhan_sample;
      this.formTambah.ukuranKemasanSample = editVar.jumlah_bungkus_botol;
    },
    onImport() {
      this.$bvModal.show("modal-import-produk");
    },
    generateTemplate() {
      const wb = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet([{}]);
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Kategori",
            "Nama Bahan",
            "Jumlah Terkecil",
            "Satuan Terkecil",
            "Harga / Satuan Terkecil"
          ]
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { width: 8 },
        { width: 20 },
        { width: 14 },
        { width: 14 },
        { width: 22 }
      ];
      XLSX.utils.book_append_sheet(wb, worksheet, `Sheet 1`);
      XLSX.writeFile(wb, `Template Import User.xlsx`);
    },
    handleImport(e) {
      e.preventDefault();
      this.importLoading = true;
      let formData = new FormData();
      formData.append("file", this.dataImport);
      axios
        .post(`${process.env.VUE_APP_URL_GRAPHQL}/produk/import`, formData, {
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        })
        .then(response => {
          if (response) {
            this.importLoading = false;
            this.$bvModal.hide("modal-import-produk");
            this.dataImport = null;
            this.$apollo.queries.listProduk.refetch();
            this.toastAlert(this.$t("alert.import"));
          }
        })
        .catch(err => {
          this.importLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    selectAll() {
      this.prodIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataProduk) {
          this.prodIDs.push(this.dataProduk[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    closeModal() {
      this.$bvModal.hide("modal-produk");
    },
    resetForm() {
      this.formTambah.id = "";
      this.formTambah.nama_produk = "";
      this.formTambah.ingredient = "";
      this.formTambah.category = "";
      this.formTambah.crops = [];
      this.formTambah.label = "";
      this.formTambah.doseGr = "";
      this.formTambah.doseHa = "";
      this.formTambah.regHolder = "";
      this.formTambah.regNumber = "";
      this.formTambah.price = 0;
      this.formTambah.dosisPerTangki = "";
      this.formTambah.jumlahTangki = "";
      this.formTambah.dosisPerDemo = "";
      this.formTambah.ukuranKemasanSample = "";
      this.$v.$reset();
    },
    async onEdit(data) {
      this.modalTitle = this.$t("products.edit");
      this.isEdit = true;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();

      await this.fetchKategori();
      await this.fetchTanaman();
      // console.log(data);
      let editVar = data;
      for (let index = 0; index < data.detail_produk.length; index++) {
        if (data.detail_produk[index].crops) {
          data.detail_produk[index] = data.detail_produk[index].crops;
        }
      }

      this.formTambah.id = editVar.id;
      this.formTambah.nama_produk = editVar.brand;
      this.formTambah.ingredient = editVar.ingredient;
      this.formTambah.category = editVar.category.id;
      this.formTambah.crops = editVar.detail_produk;
      this.formTambah.label = editVar.label;
      this.formTambah.doseGr = editVar.dosegr;
      this.formTambah.doseHa = editVar.doseHaKg;
      this.formTambah.regHolder = editVar.regHolder;
      this.formTambah.regNumber = editVar.regNum;

      this.formTambah.price = editVar.price;
      this.formTambah.dosisPerTangki = editVar.volume_per_demoplot;
      this.formTambah.jumlahTangki = editVar.jumlah_tangki;
      this.formTambah.dosisPerDemo = editVar.kebutuhan_sample;
      this.formTambah.ukuranKemasanSample = editVar.jumlah_bungkus_botol;

      this.$bvModal.show("modal-produk");
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        let payload = this.formTambah;
        // console.log(payload);
        for (let index = 0; index < payload.crops.length; index++) {
          payload.crops[index] = payload.crops[index].id;
        }
        if (this.isEdit) {
          return new Promise((resolve, reject) => {
            const editProduk = this.$apollo.mutate({
              mutation: M_EDIT_PRODUK,
              variables: {
                id: payload.id,
                brand: payload.nama_produk,
                ingr: payload.ingredient,
                category_id: payload.category,
                crop: payload.crops,
                label: "-",
                dosegr: payload.doseGr,
                doseHaKg: payload.doseHa,
                regHolder: this.regHolderOptions[0].value,
                regNum: "-",
                price: parseInt(payload.price),
                dosisPerTangki: parseFloat(payload.dosisPerTangki),
                jumlahTangki: parseInt(payload.jumlahTangki),
                dosisPerDemo: parseFloat(payload.dosisPerDemo),
                ukuranKemasanSample: parseFloat(payload.ukuranKemasanSample)
              }
            });
            if (resolve) {
              resolve(editProduk);
            } else {
              reject(editProduk);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModal();
                this.$apollo.queries.listProduk.refetch();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addProduk = this.$apollo.mutate({
              mutation: M_ADD_PRODUK,
              variables: {
                brand: payload.nama_produk,
                ingr: payload.ingredient,
                category_id: payload.category,
                crop: payload.crops,
                label: "-",
                dosegr: payload.doseGr,
                doseHaKg: payload.doseHa,
                regHolder: this.regHolderOptions[0].value,
                regNum: "-",
                price: parseInt(payload.price),
                dosisPerTangki: parseFloat(payload.dosisPerTangki),
                jumlahTangki: parseInt(payload.jumlahTangki),
                dosisPerDemo: parseFloat(payload.dosisPerDemo),
                ukuranKemasanSample: parseFloat(payload.ukuranKemasanSample)
              }
            });
            if (resolve) {
              resolve(addProduk);
            } else {
              reject(addProduk);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.save"));
                this.closeModal();
                this.$apollo.queries.listProduk.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          return new Promise((resolve, reject) => {
            const delProduk = this.$apollo.mutate({
              mutation: M_DEL_PRODUK,
              variables: {
                id: id
              }
            });
            if (resolve) {
              resolve(delProduk);
            } else {
              reject(delProduk);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.delete"));
                this.$apollo.queries.listProduk.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      });
    },
    publishSample(id, isSample) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: publishSample,
              variables: {
                id: id,
                isSample: isSample
              }
            })
            .then(() => {
              this.$apollo.queries.listProduk.refetch();
              this.toastAlert("Produk berhasil diupdate!");
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    publishMatpro(id, isMatpro) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: publishMatpro,
              variables: {
                id: id,
                isMatpro: isMatpro
              }
            })
            .then(() => {
              this.$apollo.queries.listProduk.refetch();
              this.toastAlert("Produk berhasil diupdate!");
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    // async handleExport1() {
    //   try {
    //     const result = await this.$apollo.query({
    //       query: Q_LIST_PRODUK,
    //       variables: {
    //         page: this.currPage,
    //         limit: this.totalData,
    //         keyword: this.filterInput
    //       }
    //     });
    //     const list = result.data.listProduk.produks;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = [
    //         "Brand",
    //         "Category",
    //         "Ingredients",
    //         "Label",
    //         "DoseGr",
    //         "DoseHaKg",
    //         "RegHolder",
    //         "RegNumber",
    //         "Price",
    //         "Vol/Demoplot",
    //         "Jml Tangki",
    //         "Keb. Sampel",
    //         "Jml Bks Btl",
    //         "Sample",
    //         "Matpro"
    //       ];
    //       const filterVal = [
    //         "brand",
    //         "category",
    //         "ingredient",
    //         "label",
    //         "dosegr",
    //         "doseHaKg",
    //         "regHolder",
    //         "regNum",
    //         "price",
    //         "volume_per_demoplot",
    //         "jumlah_tangki",
    //         "kebutuhan_sample",
    //         "jumlah_bungkus_botol",
    //         "isSample",
    //         "isMatpro"
    //       ];
    //       setTimeout(() => {
    //         list.map(a => {
    //           if (a.category) {
    //             a.category = a.category.category;
    //           }
    //           if (a.price) {
    //             a.price = `Rp. ${a.price
    //               .toString()
    //               .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    //           }
    //           if (a.isSample) {
    //             a.isSample = "YES";
    //           } else {
    //             a.isSample = "NO";
    //           }
    //           if (a.isMatpro) {
    //             a.isMatpro = "YES";
    //           } else {
    //             a.isMatpro = "NO";
    //           }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    // },
    async handleExport() {
      this.isLoading = true;
      await axios
        .get(process.env.VUE_APP_URL_GRAPHQL + "/produk/excel", {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `produk-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style>
.produk-table thead {
  background-color: #fafafa;
  border-radius: 50px;
}

.produk-table tbody tr td {
  /* background-color: red; */
  padding-top: 0.6rem;
  padding-bottom: 0.8rem;
  vertical-align: middle;
}

.produk-table tbody tr td ul {
  margin-bottom: 0;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
